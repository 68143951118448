import { gql } from '@apollo/client';
import graphClient from '@/api/graph';
import {Institution} from '@/constants/institutions';
import _ from 'lodash';

export const GET_COURSES = gql`
query getCourses(
    $filter: custom_crse_offer_view_bool_exp,
) {
    items: custom_crse_offer_view(
        where:$filter,
    ) {
        courseId: crse_id
        subject
        courseNumber: catalog_nbr
        institution
        units: units_acad_prog
        academicGroup: acad_group
    }
}
`;

export type CourseExtract = {
    units: number;
    courseId: string;
    subject: string;
    courseNumber: string;
    institution: string;
    academicGroup: string;

    // if transferring in
    sendingCourseId?: string;
};
export const getCoursesByIds = async (courseIds: string[]): Promise<CourseExtract[]> => {
    if (!courseIds?.length) {
        return [];
    }
    const results = await graphClient.query({
        query: GET_COURSES,
        variables: {
            filter: {
                crse_id: {
                    _in: courseIds
                }
            }
        },
        fetchPolicy: 'cache-first'
    });

    return results.data.items || [];
};

export const getCoursesByScribedFormat = async (courses: string[], institution: string): Promise<CourseExtract[]> => {
    if (!courses?.length) return [];

    const filter = {
        _or: courses.map(course => {
            const [ subject, courseNumber ] = course.split(' ');

            let subjectFilter = {};
            if (subject !== '@') {
                subjectFilter = {
                    subject: {_eq: subject}
                };
            }

            let courseNumberFilter = {};
            if (courseNumber !== '@') {
                if (courseNumber.includes('@')) {

                }
            }
            courseNumberFilter = courseNumber.includes('@')
                ? {
                    catalog_nbr: {_like: courseNumber.replace('@', '%')}
                }
            : {
                    catalog_nbr: {_eq: courseNumber}
                };

            const _andFilter = [ subjectFilter, courseNumberFilter, { institution: { _eq: institution } } ];
            _.remove(_andFilter, _.isEmpty);

            return {
                _and: _andFilter,
            };
        }),
    };

    const results = await graphClient.query({
        query: GET_COURSES,
        variables: {
            filter,
        },
        fetchPolicy: 'cache-first'
    });

    return results.data.items || [];
};
