import graphClient from '@/api/graph';
import getStudentInfoById from '@/api/graphql/queries/getStudentInfoById';
import {StudentEnrollment, StudentInfo} from '@/api/types';
import {simulateCompletedEnrollments} from '@/utils/enrollmentUtils';
import {
    isAnyWritingIntensive,
    isWildcardWithAttribute,
    normalizeDegreeBlockValue, parseAttributeValue,
    parseDegreeInfo, requiresAttribute
} from '@/degrees/audit/utils';
import {summarizeStats} from '@/utils/degreeUtils';
import {RequirementBlock} from '@/degrees/types';
import {getRequirementBlock} from '@/api/graphql/queries/getRequirementBlock';
import auditBlockRule from '@/degrees/audit/auditBlockRule';
import {
    AttributeCoursesMap,
    AuditedClassCreditRule,
    AuditedRequirementBlock,
    StudentInfoWithDegree
} from '@/degrees/audit/types';
import _ from 'lodash';
import auditRequirementBlock from '@/degrees/audit/auditRequirementBlock';
import {searchStudentInfo} from '@/api/graphql/queries/searchStudentInfo';
import {BlockType} from '@/degrees/common';
import {parseLatestAcademicProgramFromStudentInfo} from '@/api/transformers';
import getTransferCourses from '@/api/getTransferCourses';
import {
    CourseCoverage,
    CourseCoverageWithCatalogInfo,
    findMinimumCoursesFromRules, wildcardMatch
} from '@/degrees/audit/findMinimumCourses';
import {CourseExtract, getCoursesByScribedFormat} from '@/api/graphql/queries/getCourses';
import wricCoursesByInstitution from '@/constants/wricCoursesByInstitution';
import requirementDesignationCourses from '@/constants/requirementDesignationCourses';


export interface AuditStudentResponse {
    studentInfo: StudentInfoWithDegree;
    auditedBlock: AuditedRequirementBlock | null;
    errors: string[];
}
export async function auditStudent(studentId: string): Promise<AuditStudentResponse> {
    const results = await graphClient.query({
        query: getStudentInfoById,
        variables: {
            studentId
        }
    });

    let errors: string[] = [];

    let studentInfo = results.data.items[0];

    if (!studentInfo) {
        console.warn('student was not found in ps_names. proceeding to search all tables anyways...');
        studentInfo = await searchStudentInfo(studentId);
    }

    if (studentInfo) {
        const simulatedInfo: StudentInfo = {
            ...studentInfo,
            enrollments: simulateCompletedEnrollments(studentInfo.enrollments),
        };

        const withDegreeInfo = parseDegreeInfo(simulatedInfo);
        const enrollmentSummary = summarizeStats(simulatedInfo.enrollments);
        console.log('simulatedInfo.enrollments: ', simulatedInfo.enrollments);
        console.log('enrollmentSummary: ', enrollmentSummary);

        const studentInfoWithDegree: StudentInfoWithDegree = {
            ...simulatedInfo,
            ...withDegreeInfo,
            ...enrollmentSummary
        };

        console.log('studentInfoWithDegree: ', studentInfoWithDegree);


        // === fetching transfer courses. this should mutate existing courses with additional transfer props if applicable ===
        const destinationInstitution = withDegreeInfo.institution;

        const sendingEnrollments: StudentEnrollment[] = simulatedInfo.enrollments.filter(o => {
            return (o.institution !== destinationInstitution);
        });
        const sendingCourseIds = sendingEnrollments.map(o => o.class.courseId);

        const transferredCourses = await getTransferCourses(sendingCourseIds, destinationInstitution);

        console.log('transferredCourses: ', transferredCourses);

        if (sendingEnrollments.length !== transferredCourses.length) {
            console.warn('sendingEnrollments.length !== transferredCourses.length');
        }

        // iterate through enrollments because getTransferCourses
        sendingEnrollments.forEach(o => {

        });

        transferredCourses.forEach(transferredCourse => {
            const enrollment = sendingEnrollments.find(o => {
                return o.class.courseId === transferredCourse.sendingCourseId;
            });
            if (!enrollment) {
                console.error('Unable to locate sending enrollment');
            } else {
                const t = {
                    courseId: transferredCourse.courseId,
                    subject: transferredCourse.subject,
                    courseNumber: transferredCourse.courseNumber,
                };
                if (enrollment.transferredAs) {
                    enrollment.transferredAs.push(t);
                } else {
                    enrollment.transferredAs = [ t ];
                }
            }
        });
        // ============

        console.log('auditStudent props: ', studentInfoWithDegree);

        const onlyEarnedCreditNoLabs = _.filter(simulatedInfo.enrollments, o => {
            return o.class.courseComponent !== 'LAB' &&
                o.earnCredit === 'Y';
        });

        let auditedBlock: AuditedRequirementBlock | null = null;

        if (studentInfoWithDegree.degree) {
            const degreeBlockQueryProps = {
                institution: studentInfoWithDegree.institution,
                blockType: 'DEGREE' as BlockType,
                blockValue: normalizeDegreeBlockValue(studentInfoWithDegree.degree)
            };
            console.log('degreeBlockQueryProps: ', degreeBlockQueryProps);

            let block: RequirementBlock = await getRequirementBlock(degreeBlockQueryProps);

            console.log('auditStudent degree block: ', block);

            if (!block) {
                console.warn('Did not find degree block using institution from academic plan. Attempting to look up institution in latest academic program instead...');
                const program = parseLatestAcademicProgramFromStudentInfo(studentInfo);
                if (program) {
                    studentInfoWithDegree.institution = program.institution;
                }
                block = await getRequirementBlock({
                    ...degreeBlockQueryProps,
                    institution: program?.institution || ''
                });
            }

            if (!block) {
                throw new Error(`Unable to find degree block: ${degreeBlockQueryProps.blockValue} from ${degreeBlockQueryProps.institution}`);
            }

            const devBlock = _.cloneDeep(block);
            // devBlock.parseTree.body_list = devBlock.parseTree.body_list.slice(7, 8);
            // devBlock.parseTree.body_list = [
            //     {"class_credit": {"label": {"label_str": "General Elective", "label_tag": "10"}, "is_hidden": false, "is_pseudo": false, "conjunction": null, "course_list": {"list_type": "OR", "institution": "BMC01", "except_courses": [ [ "ENG__hidden__", "100.5__hidden__", null ], [ "ENG__hidden__", "101__hidden__", null ], [ "ENG__hidden__", "201__hidden__", null ] ], "requirement_id": "RA000144", "include_courses": [], "scribed_courses": [ [ [ "@", "@", null ] ] ]}, "max_classes": null, "max_credits": 4, "min_classes": null, "min_credits": 4, "allow_classes": null, "allow_credits": null}}
            // ];
            // devBlock.parseTree.header_list = [];

            auditedBlock = await auditRequirementBlock(devBlock, {
                ...studentInfoWithDegree,
                enrollments: onlyEarnedCreditNoLabs
            });


            const missingClassCreditRules = auditedBlock.requiredClassCreditRules.filter(o => !o.completed);

            missingClassCreditRules.forEach((rule: AuditedClassCreditRule) => {
                const populatedAttributeCourses: AttributeCoursesMap = {};

                rule.rule.course_list.scribed_courses.map(courses => {

                    for (let i = courses.length - 1; i >= 0; i--) {
                        const s = courses[i];

                        if (isAnyWritingIntensive(s)) {
                            courses.splice(i, 1);
                            populatedAttributeCourses.wric = wricCoursesByInstitution[studentInfoWithDegree.institution];
                        } else if (requiresAttribute(s)) {
                            const expression = s[2]!;
                            const value = parseAttributeValue(expression);

                            const designationCourses: string[] = requirementDesignationCourses[studentInfoWithDegree.institution]?.[value] || [];

                            if (s[0] === '@' && s[1] === '@') {
                                populatedAttributeCourses[value] = designationCourses;
                                courses.splice(i, 1);

                            } else if (s[0] !== '@' && s[1] === '@') {
                                populatedAttributeCourses[value] = designationCourses.filter(course => course.split(' ')[0] === s[0]);
                                courses.splice(i, 1);
                            } else if (s[0] !== '@' && s[1] !== '@') {
                                courses[i] = [ s[0], s[1], null ];
                            } else {
                                console.warn('scribedCourse requires attribute but expression is unhandled. scribedCourse: ', s);
                            }
                        }
                    }
                });

                rule.populatedAttributeCourses = populatedAttributeCourses;
            });


            const mininumCoursesFromRemainingRequirements = findMinimumCoursesFromRules(missingClassCreditRules);


            const courseExtracts: CourseExtract[] = await getCoursesByScribedFormat(mininumCoursesFromRemainingRequirements.courses.map(o => o.course), destinationInstitution);
            const courseCoverage = mininumCoursesFromRemainingRequirements.courses;

            let shortestPathTotalUnits = 0;

            for (const coverage of courseCoverage) {

                const [ subject, courseNumber ] = coverage.course.split(' ');

                const extract: CourseExtract | Record<string, any> = courseExtracts.find(o => {
                    // return o.subject === subject && o.courseNumber === courseNumber;
                    return wildcardMatch(`${o.subject} ${o.courseNumber}`, coverage.course);
                }) || {};

                coverage.courseId = extract.courseId || '';
                coverage.units = extract.units || 0;
                shortestPathTotalUnits += extract.units || 0;
            }

            auditedBlock.missingClassCreditRules = missingClassCreditRules;
            auditedBlock.shortestPathRemaining = mininumCoursesFromRemainingRequirements;
            auditedBlock.shortestPathTotalUnits = shortestPathTotalUnits;


        } else {

            errors.push('No academic plans were found for this student.');
        }

        return {
            studentInfo: studentInfoWithDegree,
            auditedBlock,
            errors
        };

    } else {
        throw new Error('Unable to find studentId: ' + studentId);
    }
}
