import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const PageContainerStyle = styled.div`
    ${tw`text-base`}
    //scrollbar-gutter: stable;
    //overflow-y: auto;
    margin: auto;

    ${tw`mt-8 px-4 py-4`}
    .header {
        ${tw`mb-6 pb-2`}
        border-bottom: 1px solid grey;

        h3 {
            font-size: 2rem;
            font-weight: 500;
        }
    }

    .field {
        ${tw`mb-4 text-lg`}
        label {
            ${tw`font-medium mr-2`}
        }
    }

    .remaining-semesters-container {
        ${tw`mb-4`}
        
        h4 {
            font-size: 1.5rem;
            font-weight: 400;
        }
    }
    
    .remaining-requirements-container {
        ${tw`bg-white rounded flex-1`}
        ${tw`mt-4 mb-8 border-solid border border-gray-300 pb-4`} 
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);

        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    
    .degree-block-container {
        ${tw`bg-white rounded flex-1`}
        ${tw`border-solid border border-gray-300`}

        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
        > .audited-block-container {
            border: none;
        }
    }
    
    ul.remaining-rules-list {
        list-style-position: inside;

        li::marker {
            //font-size: 0.5em; /* Adjust size as needed */
            font-size: 1.2rem;
        }
    }


    ol.remaining-rules-list {
        list-style-type: none;
        margin-left: 0;
    }

    ol.remaining-rules-list > li {
        counter-increment: customlistcounter;
        font-size: 1.2rem;
        .amount-and-type {
            font-size: 1.2rem;
            font-weight: 500;
        }
        .in-course-list-string {
            font-weight: 300;
        }
        
        .from-attributes-container {
            margin-top: 0.5rem;
            
            .attribute-line {
                font-size: 1.2rem;
                font-weight: 300;
                strong {
                    font-weight: 400;
                }
            }
        }
    }

    ol.remaining-rules-list > li:before {
        content: counter(customlistcounter) ")";
        //font-weight: bold;
        float: left;
        width: 1.5em;
        color: #535353;
    }


    ol.remaining-rules-list {
        list-style-position: inside;
        //li:before {
        //    content: counter(level1) " "; /*Instead of ". " */
        //    counter-increment: level1;
        //}

        li::marker {
            font-weight: 500;
            //font-size: 0.875rem; /* text-sm */
            font-size: 1.2rem;

        }

        li:not(:last-child) {
            border-bottom: 1px solid #ccc;
        }
    }
    
    .minimum-courses-container {
        font-size: 1.2rem;
        table {
            border: 1px solid #ccc;
            //width: 100%;

            thead tr {

                box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.6);
                th {
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                }
            }
            
            tbody {
                tr:not(:last-child) {
                    border-bottom: 1px solid #ccc;
                }
                
                td {
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                }
            }
            
            
        }
    }
    .minimum-courses-summary {
        margin-top: 2rem;
        font-size: 1.2rem;
        > div {
            margin-bottom: 0.5rem;
        }
    }
`;

export const StudentFormStyle = styled.form`
    ${tw`mb-6`}
    
    
    input {
        -webkit-appearance: none;
        border: 1px solid grey;
        border-radius: 3px;
        padding-left: 8px;
        padding-right: 8px;
        ${tw`text-lg py-2 px-3`}
    }
    
    button {
        ${tw`px-8 text-lg`}
    }
    

    .details-container {
        ${tw`flex flex-row`}
        > * {
            ${tw`w-full`}
        }
    }

`;

export const DegreeRequirementsStyle = styled.div`
    ${tw`mb-8`}
`;


export const AuditedBlockStyle = styled.div`
    
    ${tw`px-4 py-4 border border-solid border-gray-300`}
    
`;


interface AuditedRuleEntryStyleProps {
    isRoot: boolean;
}


export const AuditedRuleEntryStyle = styled.div<AuditedRuleEntryStyleProps>`
    ${props => props.isRoot ?
        `
        border-bottom: 1px solid grey;
        margin-bottom: 10px;
        
        `
        : ''    
    }
    ${tw`mb-8`}
`;

export const EnrollmentHistoryContainerStyle = styled.div`
    ${tw`mb-8`}
    table {
        ${tw`w-full`}
    }
    
`;
