// @ts-nocheck
// Utility to parse "SUBJECT NUMBER"
import {ScribedCourse} from '@/degrees/rules';
import {AVG_COURSE_UNITS} from '@/degrees/audit/constants';
import {formatScribedCourse} from '@/degrees/audit/utils';
import {AuditedClassCreditRule} from '@/degrees/audit/types';

export function parseCourse(course: string) {
  const parts: string[] = course.split(' ');
  const subject = parts[0];
  const number = parts[1];
  const attributes = parts.slice(2).join(' ');
  return { subject, number, attributes };
}

export function isIdentifiable(course: string): boolean {
  return !course.includes('@') || !!parseCourse(course).attributes;
}

// Checks whether a single knownCourse (like "MAT 101") matches a wildcard (like "@ 1@" or "MAT 1@")
export function wildcardMatch(knownCourse: string, wildcard: string) {
  const { subject: knownSubj, number: knownNum } = parseCourse(knownCourse);
  const { subject: wcSubj, number: wcNum } = parseCourse(wildcard);

  // Match subject
  // '@' means any subject; otherwise must match exactly
  if (wcSubj !== "@" && wcSubj !== knownSubj) return false;

  // Match course number
  // Replace '@' in the wildcard number with a regex equivalent, then test
  const regexPattern = wcNum.replace(/@/g, ".*"); // e.g. "1@" => "1.*"
  const regex = new RegExp(`^${regexPattern}$`);
  return regex.test(knownNum);
}

// Expand any wildcard courses into actual course strings (based on the full set of knownCourses)
function expandWildcardCourses(courses, allKnownCourses) {
  const expanded = [];
  courses.forEach(c => {
    if (isIdentifiable(c)) {
      expanded.push(c);
    } else {

      const matches = allKnownCourses.filter(kc => wildcardMatch(kc, c));
      if (matches.length) {
        expanded.push(...matches);
      } else {
        expanded.push(c);
      }

    }
  });
  // Remove duplicates
  return [ ...new Set(expanded) ];
}

// Standard combination logic
function getCombinations(arr, k) {
  const results = [];
  function backtrack(start, combo) {
    if (combo.length === k) {
      results.push([ ...combo ]);
      return;
    }
    for (let i = start; i < arr.length; i++) {
      combo.push(arr[i]);
      backtrack(i + 1, combo);
      combo.pop();
    }
  }
  backtrack(0, []);
  return results;
}

export function findMinimumCoursesFromRules(rules: AuditedClassCreditRule[]): CoverageInfo {
  const requirements = parseAuditedClassCreditRules(rules);
  return findMinimumCourses(requirements);
}

export function findMinimumCourses(requirements): CoverageInfo {

  // Gather all distinct "normal" (non-wildcard) courses from all requirements
  const allKnownCourses = new Set();
  requirements.forEach(r => {
    r.courses.forEach(c => {
      if (isIdentifiable(c)) {
        allKnownCourses.add(c);
      }
    });
  });

  // For each requirement, expand wildcard courses into a flattened array of actual matches
  // Then generate all combos
  const requirementsWithCombos = requirements.map(r => {

    const expandedCourses = expandWildcardCourses(r.courses, [ ...allKnownCourses ]);

    // // Also include any non-wildcard courses that might not have been in 'allKnownCourses' originally
    // // (handles edge case if the only mention of a course is via wildcard in another requirement)
    // r.courses.forEach(c => {
    //   if (isIdentifiable(c)) expandedCourses.push(c);
    // });

    const uniqueCourses = [ ...new Set(expandedCourses) ];
    const combos = getCombinations(uniqueCourses, r.numRequired)
      .map(c => c.slice().sort())
      .sort((a, b) => {
        for (let i = 0; i < Math.min(a.length, b.length); i++) {
          const cmp = a[i].localeCompare(b[i]);
          if (cmp !== 0) return cmp;
        }
        return a.length - b.length;
      });
    return {
      requirementId: r.requirementId,
      combos
    };
  });

  requirementsWithCombos.sort((a, b) => a.combos.length - b.combos.length);

  let globalBest = null;
  let globalBestPath = null;

  function backtrack(idx, currentUnion, path) {
    if (globalBest && currentUnion.size >= globalBest.size) return;
    if (idx === requirementsWithCombos.length) {
      globalBest = new Set(currentUnion);
      globalBestPath = [ ...path ];
      return;
    }
    for (const combo of requirementsWithCombos[idx].combos) {
      const newUnion = new Set([ ...currentUnion, ...combo ]);
      backtrack(idx + 1, newUnion, [ ...path, combo ]);
    }
  }

  backtrack(0, new Set(), []);

  // Build coverage details
  const coverage = {};
  if (globalBestPath) {
    for (let i = 0; i < globalBestPath.length; i++) {
      const reqId = requirementsWithCombos[i].requirementId;
      const chosenCombo = globalBestPath[i];
      chosenCombo.forEach(course => {
        coverage[course] = coverage[course] || [];
        coverage[course].push(reqId);
      });
    }
  }

  const courses = Object.entries(coverage).map(([ key, value ]) => {
      return {
        course: key,
        satisfies: value
      };
  });

  return {
    // courses: [...globalBest],
    count: globalBest ? globalBest.size : 0,
    courses: courses
  };
}

export type CoverageInfo = {
  count: number;
  courses: CourseCoverage[];
};

export type CourseCoverage = {
  course: string;
  satisfies: string[];
  courseId?: string;
  units?: number;
};

export type CourseCoverageWithCatalogInfo = CourseCoverage & {

};

export type ClassCreditRequirement = {
  requirementId: string;
  numRequired: number;
  courses: string[];
  remainingClasses: number;
  remainingCredits: number;
};
function parseAuditedClassCreditRules(entries: AuditedClassCreditRule[]): ClassCreditRequirement[] {
  return entries.map<ClassCreditRequirement>((audited: AuditedClassCreditRule, i: number) => {
    const rule = audited.rule;
    let numRequired;

    const remainingClasses = audited.requiredClasses - audited.completedClasses;
    const remainingCredits = audited.requiredCredits - audited.completedCredits;

    numRequired = remainingClasses || Math.ceil(remainingCredits / AVG_COURSE_UNITS);

    let courses = audited.rule.course_list.scribed_courses.map(courses => courses.map((s: ScribedCourse) => {
      return formatScribedCourse(s);
    })).flat();

    if (audited.populatedAttributeCourses) {
      for (const attr in audited.populatedAttributeCourses) {
        courses.push(...audited.populatedAttributeCourses[attr]);
      }
    }
    courses = _.uniq(courses);

    return {
      requirementId: `${i + 1}`,
      numRequired,
      courses,
      remainingClasses,
      remainingCredits,
    };
  });
}


// handle only for CREDIT type rules
export function meetsAllCreditRequirements(chosenCourses: CourseCoverage[], requirements: ClassCreditRequirement[]) {
  // For each requirement
  for (const req of requirements) {
    if (!req.remainingCredits) continue;

    // Sum credits from all chosen courses that belong to this requirement
    let fulfilledCredits = 0;
    chosenCourses.forEach(course => {
      if (req.courses.includes(course.course)) {
        fulfilledCredits += course.units || 0;
      }
    });
    // If we don't meet or exceed neededCredits, fail immediately
    if (fulfilledCredits < req.remainingCredits) {
      return false;
    }
  }
  return true;
}

/**
 * Attempts to remove redundant courses from chosenCourses
 * while keeping all requirements satisfied.
 */
export function finalOptimization(chosenCourses: CourseCoverage[], requirements: ClassCreditRequirement[]) {
  let optimized = true;
  while (optimized) {
    optimized = false;
    // Try removing each course in turn
    for (let i = 0; i < chosenCourses.length; i++) {
      const temp = [ ...chosenCourses ];
      temp.splice(i, 1); // remove course i temporarily

      if (meetsAllCreditRequirements(temp, requirements)) {
        // It's still valid without this course
        chosenCourses.splice(i, 1);
        optimized = true;
        break; // restart from beginning
      }
    }
  }
  return chosenCourses;
}
