import { gql } from '@apollo/client';

export default gql`
fragment enrollments on ps_stdnt_enrl {
    strm    
    institution
    classId: class_nbr
    sessionCode: session_code
    grade: crse_grade_off
    gradePoints: grd_pts_per_unit
    unitsTaken: unt_taken
    
    unitsAttempted: units_attempted
    earnCredit: earn_credit
    unitsEarned: unt_earned
    
    statusReason: enrl_status_reason
    lastActionProcessed: enrl_actn_prc_last
    
    requirementDesignation: rqmnt_designtn
    
    class {
        courseId: crse_id
        courseNumber: catalog_nbr
        subject
        descr
        classId: class_nbr
        courseComponent: ssr_component
        attributes {
            courseAttribute: crse_attr
            courseAttributeValue: crse_attr_value
        }
        wric {
            courseId: crse_id
        }
        catalog {
            requirementDesignation: rqmnt_designtn
        }
    }
}
`;
