import { RequirementDesignation, Option } from '@/api/types';

export const RequirementDesignationName: any = {
    FCE: 'Flexible Core - Creative Expression',
    FCER: 'Flexible Core - Creative Expression',
    FCEC: 'Flexible Core - Creative Expression',
    FCED: 'Flexible Core - Creative Expression',
    FIS: 'Flexible Core - Individual and Society',
    FISR: 'Flexible Core - Individual and Society',
    FISC: 'Flexible Core - Individual and Society',
    FISD: 'Flexible Core - Individual and Society',
    FSW: 'Flexible Core - Scientific World',
    FSWC: 'Flexible Core - Scientific World',
    FSWD: 'Flexible Core - Scientific World',
    FSWR: 'Flexible Core - Scientific World',
    FUS: 'Flexible Core - US Experience in its Diversity',
    FUSR: 'Flexible Core - US Experience in its Diversity',
    FUSC: 'Flexible Core - US Experience in its Diversity',
    FUSD: 'Flexible Core - US Experience in its Diversity',
    FWG: 'Flexible Core - World Cultures & Global Issues',
    FWGR: 'Flexible Core - World Cultures & Global Issues',
    FWGC: 'Flexible Core - World Cultures & Global Issues',
    FWGD: 'Flexible Core - World Cultures & Global Issues',
    REC: 'Required Core - English Composition',
    RECR: 'Required Core - English Composition',
    RECC: 'Required Core - English Composition',
    RECD: 'Required Core - English Composition',
    RLP: 'Required Core - Life and Physical Sciences',
    RLPR: 'Required Core - Life and Physical Sciences',
    RLPC: 'Required Core - Life and Physical Sciences',
    RLPD: 'Required Core - Life and Physical Sciences',
    RMQ: 'Required Core - Mathematical&QuantitativeReasoning',
    RMQR: 'Required Core - Mathematical&QuantitativeReasoning',
    RMQC: 'Required Core - Mathematical&QuantitativeReasoning',
    RMQD: 'Required Core - Mathematical&QuantitativeReasoning',
    RLA: 'Regular Liberal Arts',
    CLA: 'Compensatory Liberal Arts',
    CNL: 'Compensatory Non-Liberal Arts',
    COPC: 'College Option',
    COPD: 'College Option',
    COPN: 'College Option',
    COPR: 'College Option',
    DLA: 'Developmental Liberal Arts',
    DNL: 'Developmental Non-Liberal Arts',
    GLA: 'Graduate Liberal Arts',
    GNL: 'Graduate Non-Liberal Arts',
    LAD: 'Doctoral Liberal Arts',
    LLA: 'Laboratory Liberal Arts',
    LNL: 'Laboratory Non-Liberal Arts',
    MLA: 'Message Liberal Arts',
    MNL: 'Message Non-Liberal Arts',
    NLA: 'Remedial Liberal Arts',
    NLD: 'Doctoral Non-Liberal Arts',
    NLM: 'Medical Non-Liberal Arts',
    NNL: 'Remedial Non-Liberal Arts',
    RNL: 'Regular Non-Liberal Arts',

    // custom
    wric: 'Writing Intensive',
};

const requirementDesignations: Option<RequirementDesignation>[] = [
    {
        value: 'FCE',
        queryValues: [ 'FCER', 'FCEC', 'FCED' ],
        label: 'Flexible Core - Creative Expression'
    },
    {
        value: 'FIS', // values don't seem to match what is in datatable
        queryValues: [ 'FISR', 'FISC', 'FISD' ],
        label: 'Flexible Core - Individual and Society'
    },
    {
        value: 'FSW',
        queryValues: [ 'FSWC', 'FSWD', 'FSWR' ],
        label: 'Flexible Core - Scientific World'
    },
    {
        value: 'FUS',
        queryValues: [ 'FUSC', 'FUSD', 'FUSR' ],
        label: 'Flexible Core - US Experience in its Diversity'
    },
    {
        value: 'FWG',
        queryValues: [ 'FWGR', 'FWGD', 'FWGC' ],
        label: 'Flexible Core - World Cultures & Global Issues'
    },
    {
        value: 'REC',
        queryValues: [ 'RECR', 'RECD', 'RECC' ],
        label: 'Required Core - English Composition'
    },
    {
        value: 'RLP',
        queryValues: [ 'RLPR', 'RLPC', 'RLPD' ],
        label: 'Required Core - Life and Physical Sciences'
    },
    {
        value: 'RMQ',
        queryValues: [ 'RMQD', 'RMQR', 'RMQC' ],
        label: 'Required Core - Mathematical&QuantitativeReasoning'
    }
];

export default requirementDesignations;
