import {CourseComponent, CourseGroup, StudentEnrollment} from '@/api/types';
import _ from 'lodash';
import {enrollments} from '@/api/graphql/fragments';

export const getCourseSectionComponents = (courseGroup: CourseGroup): CourseComponent[] => {
    const componentTypes: CourseComponent[] = _.chain(courseGroup.classes)
        .map('courseComponent')
        .uniq()
        .value();
    return componentTypes;
};

export const isEnrollmentWritingIntensive = (enrollment: StudentEnrollment):boolean => {
    return !!enrollment.class.wric?.courseId;
};

// export const parseEnrollmentAttributes = (enrollment: StudentEnrollment): string[] => {
//     const attributes = [];
//     if (isEnrollmentWritingIntensive(enrollment)) {
//         attributes.push()
//     }
//
//     return attributes;
// }
