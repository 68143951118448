export const AVG_COURSE_UNITS = 4;

export enum AuditFlagEnum {
    ignored = 'I'
}

export enum ScribeCode {
    PriorDegree = 'PDEG',
    PriorDegreeBA = 'PDBA',
    PriorDegreeBS = 'PDBS',
    Major = 'MAJOR',
    Concentration = 'CONC',
    AUDITACTION = 'AUDITACTION'
}

export enum ConditionOperator {
    Equal = '=',
    GreaterThan = '>',
    LessThan = '<',
    GreaterOrEqual = '>=',
    LessOrEqual = '<=',
    NotEqual = '<>'
}
