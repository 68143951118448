import { Term } from '@/api/types';
export const termMappings: {[key:string]: string} = {
    1002: '2000 Spring Term',
    1006: '2000 Summer Term',
    1009: '2000 Fall Term',
    1012: '2001 Spring Term',
    1016: '2001 Summer Term',
    1019: '2001 Fall Term',
    1022: '2002 Spring Term',
    1026: '2002 Summer Term',
    1029: '2002 Fall Term',
    1032: '2003 Spring Term',
    1036: '2003 Summer Term',
    1039: '2003 Fall Term',
    1042: '2004 Spring Term',
    1046: '2004 Summer Term',
    1049: '2004 Fall Term',
    1052: '2005 Spring Term',
    1056: '2005 Summer Term',
    1059: '2005 Fall Term',
    1062: '2006 Spring Term',
    1066: '2006 Summer Term',
    1069: '2006 Fall Term',
    1072: '2007 Spring Term',
    1076: '2007 Summer Term',
    1079: '2007 Fall Term',
    1082: '2008 Spring Term',
    1086: '2008 Summer Term',
    1089: '2008 Fall Term',
    1092: '2009 Spring Term',
    1096: '2009 Summer Term',
    1099: '2009 Fall Term',
    1102: '2010 Spring Term',
    1106: '2010 Summer Term',
    1109: '2010 Fall Term',
    1112: '2011 Spring Term',
    1116: '2011 Summer Term',
    1119: '2011 Fall Term',
    1122: '2012 Spring Term',
    1126: '2012 Summer Term',
    1129: '2012 Fall Term',
    1132: '2013 Spring Term',
    1136: '2013 Summer Term',
    1139: '2013 Fall Term',
    1142: '2014 Spring Term',
    1146: '2014 Summer Term',
    1149: '2014 Fall Term',
    1152: '2015 Spring Term',
    1156: '2015 Summer Term',
    1159: '2015 Fall Term',
    1162: '2016 Spring Term',
    1166: '2016 Summer Term',
    1169: '2016 Fall Term',
    1172: '2017 Spring Term',
    1176: '2017 Summer Term',
    1179: '2017 Fall Term',
    1182: '2018 Spring Term',
    1186: '2018 Summer Term',
    1189: '2018 Fall Term',
    1192: '2019 Spring Term',
    1196: '2019 Summer Term',
    1199: '2019 Fall Term',
    1202: '2020 Spring Term',
    1206: '2020 Summer Term',
    1209: '2020 Fall Term',
    1212: '2021 Spring Term',
    1216: '2021 Summer Term',
    1219: '2021 Fall Term',
    1222: '2022 Spring Term',
    1226: '2022 Summer Term',
    1229: '2022 Fall Term',
    1232: '2023 Spring Term',
    1239: '2023 Fall Term',
    1242: '2024 Spring Term',
    1249: '2024 Fall Term',
    1252: '2025 Spring Term',
    1259: '2025 Fall Term',
};

export const summerTerm = {
    id: '1226',
    year: '2023'
};

export const termOverrideName = '2023 Fall Term';

// currentTerm may or may not be the same as activeTerm. Active term is for the semester the simulation will offer courses.
// Current term is the real life current term.
export const currentTerm = {
    id: '1239',
};

export const activeTerm = {
    name: termOverrideName,
    season: 'Fall',
    year: '2023',
    id: '1239',
};

export const termNameYearLowercase = `${activeTerm.season.toLowerCase()} ${activeTerm.year} term`;

export const defaultTerm: Term = {
    // id: '1239', // this is for Fall 2023
    id: '1229', // we are now overriding this to be Fall 2022
    name: termOverrideName,
};

export const isSummerTerm = (strm: string): boolean => strm[3] === '6';
