import {
    Milestone,
    RequirementLine,
    StudentEnrollment,
    StudentGroup,
    TestPlacement,
    RequirementConditionOperator,
    RequisiteCourse,
    Condition,
    ConditionDetail,
    ConditionLine,
    EnrollmentSummary,
    RequirementGroupDetails,
    AcademicPlanInfo,
    AcademicSubplanInfo,
    AcademicProgramInfo,
    CourseInfo,
    SelectedCourse,
} from '@/api/types';
import {
    parseDynamicConditions,
    parseParenthesesDepth,
    RequirementGroupDetailsWithDepth
} from '@/utils/catalogUtils';
import _ from 'lodash';
import { convertToRequisiteCourse } from '@/api/transformers';
import { summarizeStats } from '@/utils/degreeUtils';
import { useStoreState } from '@/store';
import { useGetSimResults } from '@/api/simulation/SimulationAPI';
import { isCourseRelatedCorequisite, parseCourseCorequisiteGroups } from '@/utils/EnrollmentValidator';
import { CourseExtract } from '@/api/graphql/queries/getCourses';
import { CourseOffer } from '@/api/graphql/queries/getCoursesOffered';
import { currentTerm } from '@/constants/terms';

export interface StudentDetails {
    studentId: string;
    academicPlans: AcademicPlanInfo[],
    academicSubplans: AcademicSubplanInfo[],
    academicPrograms: AcademicProgramInfo[],
    enrollments: StudentEnrollment[],
    groups: StudentGroup[],
    placements: TestPlacement[],
    milestones: Milestone[],

    shoppingCart: SelectedCourse[],

    simCompletedEnrollments: StudentEnrollment[],

    transferCourses?: CourseExtract[],
}

export const convertCourseOfferToCompletedEnrollment = (courseOffer: CourseOffer): StudentEnrollment => {
    return {
        class: {
            academicGroup: '',
            classId: '',
            courseComponent: '',
            courseId: courseOffer.courseId,
            courseNumber: courseOffer.courseNumber,
            descr: courseOffer.catalog.courseTitle,
            subject: courseOffer.subject
        },
        requirementDesignation: '',
        simulated: true,
        classId: '',
        grade: 'A',
        gradePoints: 4,
        institution: courseOffer.institution,
        sessionCode: 'SIMFAKE',
        strm: courseOffer.term,
        unitsEarned: courseOffer.catalog.units,
        unitsTaken: courseOffer.catalog.units,
        earnCredit: 'Y',
        unitsAttempted: 'Y',
    };
};

export const useStudentDetails = (): StudentDetails => {
    const studentInfo = useStoreState(state => state.student.studentInfo!);
    const { shoppingCart, screener } = useGetSimResults();

    return {
        studentId: studentInfo.studentId,
        academicPlans: studentInfo.academicPlans,
        academicSubplans: studentInfo.academicSubplans,
        academicPrograms: studentInfo.academicPrograms,
        enrollments: studentInfo.enrollments,
        groups: studentInfo.groups,
        milestones: studentInfo.milestones,
        placements: studentInfo.placements,
        shoppingCart,
        simCompletedEnrollments: (screener.summerRegisterIntentCourses || []).map(convertCourseOfferToCompletedEnrollment),
    };
};

export enum RequisiteStatus {
    Allowed = 'Allowed',
    CoreqAllowed = 'CoreqAllowed',

    MissingPreReq = 'MissingPreReq',
    MissingCoReq = 'MissingCoReq',
    MissingCondition = 'MissingCondition',
    MissingMinimumCourses = 'MissingMinimumCourses',
    MissingMinimumUnits = 'MissingMinimumUnits',
    Antirequisite = 'Antirequisite',
    UnknownError = 'UnknownError',
    NotImplemented = 'NotImplemented',

    // This is reserved for Shopping Cart as a catch-all when validating.
    NotAllowed = 'NotAllowed',
}

export interface RequisiteValidationResult {
    status: RequisiteStatus;
    message?: string;
    enrollments?: StudentEnrollment[];
    completed?: CourseExtract[];
    meta?: any;
}

// type EnrollmentOrTransferCourse = StudentEnrollment | CourseExtract;
//
// function isTransferCourse(c: EnrollmentOrTransferCourse): c is CourseExtract {
//   return (c as CourseExtract).courseId !== undefined;
// }
//
// function getCourseIdFromEnrollment(c: EnrollmentOrTransferCourse): string {
//     if (isTransferCourse(c)) {
//         return c.courseId
//     } else {
//         return c.class.courseId;
//     }
// }

export const simulateCompletedEnrollments = (enrollments: StudentEnrollment[] = []): StudentEnrollment[] => {
    return enrollments.map((o) => {
        const props: Partial<StudentEnrollment> = {};

        // check for unitsAttempted code equal to "In Progress"
        if (!o.grade && o.unitsAttempted === 'I') {
            props.earnCredit = 'Y';
            props.unitsEarned = o.unitsTaken;
            props.gradePoints = 4;
            props.grade = 'A';
            props.simulated = true;
        }
        return {
            ...o,
            ...props
        };
    });
};
const getCompletedAndTransferredCourses = (student: StudentDetails): CourseExtract[] => {
    const completed: CourseExtract[] =
        [
            ...student.simCompletedEnrollments,
            ...student.enrollments
        ]
            .filter(o => o.gradePoints || o.strm === currentTerm.id)
            .map<CourseExtract>((o) => ({
                institution: o.institution,
                units: o.unitsEarned,
                courseId: o.class.courseId,
                subject: o.class.subject,
                courseNumber: o.class.courseNumber,
                academicGroup: o.class.academicGroup,
            }));
    const transferred = student.transferCourses || [];

    return [ ...completed, ...transferred ];
};

const validateGroupLineCourse = (detail: RequirementGroupDetailsWithDepth, student: StudentDetails): RequisiteValidationResult => {

    // requisiteType will always be either PRE or CO. Antirequisites do not exist on the group level.
    const { course, includeEquivalent, requisiteType } = detail;

    if (!course) return {status: RequisiteStatus.Allowed};

    const equivalentCourses = (detail.includeEquivalent === 'Y' && detail.course?.equivalentCourses?.length) ? detail.course?.equivalentCourses : [];
    const requiredCourses = [ course, ...equivalentCourses ];

    const completedCourses: CourseExtract[] = getCompletedAndTransferredCourses(student);

    for (let i = 0; i < completedCourses.length; i++) {
        for (let j = 0; j < requiredCourses.length; j++) {

            if (completedCourses[i].courseId === requiredCourses[j].courseId) {
                // Remember there are no antireqs on the group level so we can just safely return.
                // todo: course parameters. Okay to ignore for now.
                return {status: RequisiteStatus.Allowed};
            }
        }
    }

    if (detail.requisiteType === 'PRE') {
        return {status: RequisiteStatus.MissingPreReq};
    } else if (detail.requisiteType === 'CO') {

        let inShoppingCart = false;
        for (let i = 0; i < student.shoppingCart.length; i++) {
            if (inShoppingCart) break;

            for (let j = 0; j < requiredCourses.length; j++) {

                if (student.shoppingCart[i].courseId === requiredCourses[j].courseId) {
                    inShoppingCart = true;
                    console.log('isInShoppingCart');
                    break;
                }
            }
        }
        if (inShoppingCart) return {status: RequisiteStatus.CoreqAllowed};

        return {status: RequisiteStatus.MissingCoReq};
    } else {
        console.error('unknown requisiteType for detail: ', detail);
        return {status: RequisiteStatus.UnknownError};
    }
};

type HasCourseNumber = {
    courseNumber: string;
};

function isInt(value: string): boolean {
    const er = /^-?[0-9]+$/;
    return er.test(value);
}

function isLetter(value: string): boolean {
    const er = /[a-z]/i;
    return value?.length === 1 && er.test(value);
}

const compareWildcardPattern = (pattern: string, course: HasCourseNumber): boolean => {
    const { courseNumber } = course;

    let passed = true;
    for (let i = 0; i < pattern.length; i++) {
        if (pattern[i] === courseNumber[i]) continue;

        if (pattern[i] === '#' && isInt(courseNumber[i])) continue;

        if (pattern[i] === '*' && isLetter(courseNumber[i])) continue;

        passed = false;
    }
    return passed;
};

const validateGroupLineWildCard = (detail: RequirementGroupDetailsWithDepth, student: StudentDetails): RequisiteValidationResult => {

    const { academicGroup, subject, catalogNumberPattern, requisiteType } = detail;

    const completedCourses: CourseExtract[] = getCompletedAndTransferredCourses(student);

    for (let i = 0; i < completedCourses.length; i++) {
        const meetsAcademicGroup = (!academicGroup || academicGroup === completedCourses[i].academicGroup);
        const meetsSubject = (!subject || subject === completedCourses[i].subject);
        const meetsCourseNumber = (!catalogNumberPattern || compareWildcardPattern(catalogNumberPattern, completedCourses[i]));

        if (meetsAcademicGroup && meetsSubject && meetsCourseNumber) {
            return {status: RequisiteStatus.Allowed};
        }
    }


    for (let i = 0; i < student.enrollments.length; i++) {
        const meetsAcademicGroup = (!academicGroup || academicGroup === student.enrollments[i].class.academicGroup);
        const meetsSubject = (!subject || subject === student.enrollments[i].class.subject);
        const meetsCourseNumber = (!catalogNumberPattern || compareWildcardPattern(catalogNumberPattern, student.enrollments[i].class));

        if (meetsAcademicGroup && meetsSubject && meetsCourseNumber) {
            return {status: RequisiteStatus.Allowed};
        }
    }

    if (detail.requisiteType === 'PRE') {
        return {status: RequisiteStatus.MissingPreReq};

    } else if (requisiteType === 'CO') {
        for (let i = 0; i < student.shoppingCart.length; i++) {
            const meetsAcademicGroup = (!academicGroup || academicGroup === student.shoppingCart[i].academicGroup);
            const meetsSubject = (!subject || subject === student.shoppingCart[i].subject);
            const meetsCourseNumber = (!catalogNumberPattern || compareWildcardPattern(catalogNumberPattern, student.shoppingCart[i]));

            if (meetsAcademicGroup && meetsSubject && meetsCourseNumber) {
                return {status: RequisiteStatus.CoreqAllowed};
            }
        }
        return {status: RequisiteStatus.MissingCoReq};

    } else {
        console.error('unknown requisiteType for detail: ', detail);
        return {status: RequisiteStatus.UnknownError};
    }
};

const compareIntegerOperator = (operator: RequirementConditionOperator, value: number, benchmark: number): boolean => {
    switch (operator) {
        case '':
            throw new Error('Unhandled operator!');
        case 'IN':
            throw new Error('Unhandled operator!');
        case 'EQ':
            return value === benchmark;
        case 'GE':
            return value >= benchmark;
        case 'NE':
            return value !== benchmark;
        case 'LT':
            return value < benchmark;
        case 'LE':
            return value <= benchmark;
        case 'NI':
            throw new Error('Unhandled operator!');
        case 'GT':
            return value > benchmark;
    }

};

const validateConditionTest = (detail: RequirementGroupDetailsWithDepth | RequirementLine | ConditionLine, student: StudentDetails): RequisiteValidationResult => {

    const { conditionOperator, testId, testComponent, score: requiredScore } = detail;

    const matches = _.filter(student.placements, { testId, testComponent });

    for (let i = 0; i < matches.length; i++) {
        const pass = compareIntegerOperator(conditionOperator, matches[i].score, requiredScore);
        if (pass) return {status: RequisiteStatus.Allowed};
    }

    return {status: RequisiteStatus.MissingCondition};
};

const validateConditionStudentGroup = (detail: RequirementGroupDetailsWithDepth | RequirementLine | ConditionLine, student: StudentDetails): RequisiteValidationResult => {
    for (let i = 0; i < student.groups.length; i++) {
        if (
            (student.groups[i].groupName === detail.conditionData) &&
            // type guard to handle ConditionLine
            ((!('institution' in detail) || !detail.institution) || detail.institution === student.groups[i].institution)
        ) {
            return {status: RequisiteStatus.Allowed};
        }
    }
    return {status: RequisiteStatus.MissingCondition};
};


const validateConditionAcademicProgram = (detail: RequirementGroupDetailsWithDepth | RequirementLine, student: StudentDetails):RequisiteValidationResult => {
    // Edge case for handling Academic Program conditions at Medgar Evans, which we're missing data for. So handling similar to instruction permit based on connector
    if (detail.institution === 'MEC01') {
        if (detail.connect === 'AND') return { status: RequisiteStatus.Allowed };
        if (detail.connect === 'OR') return { status: RequisiteStatus.NotAllowed };
    }

    const studentPrograms: string[] = student.academicPrograms.map(o => o.academicProgram);


    if (detail.conditionOperator === 'EQ' &&
        studentPrograms.indexOf(detail.conditionData) !== -1
    ) {
        return {status: RequisiteStatus.Allowed};
    }

    if (detail.conditionOperator === 'NE' &&
        studentPrograms.indexOf(detail.conditionData) === -1
    ) {
        return {status: RequisiteStatus.Allowed};
    }
    return {status: RequisiteStatus.MissingCondition};
};

const INSTRUCTOR_PERMIT_PLAN = 'PERMIT-UG';

const validateInstructorPermitPlan = (detail: RequirementGroupDetailsWithDepth | RequirementLine, student: StudentDetails): RequisiteValidationResult => {
    if (detail.conditionData !== INSTRUCTOR_PERMIT_PLAN) {
        throw new Error('Expecting requirement detail to be of INSTRUCTOR_PERMIT_PLAN');
    }
    if (detail.connect === 'AND') return { status: RequisiteStatus.Allowed };
    if (detail.connect === 'OR') return { status: RequisiteStatus.NotAllowed };

    return { status: RequisiteStatus.Allowed };
};

const validateConditionAcademicPlan = (detail: RequirementGroupDetailsWithDepth | RequirementLine, student: StudentDetails):RequisiteValidationResult => {
    const studentPlans: string[] = student.academicPlans.map(o => o.academicPlan);

    if (detail.conditionOperator === 'IN' || detail.conditionOperator === 'NI') {
        const specifiedPlans: string[] = detail.entityGroupDetails.map(o => o.academicPlan);

        const matches = _.intersection(specifiedPlans, studentPlans);

        if (detail.conditionOperator === 'IN' && !matches.length) {
            return { status: RequisiteStatus.MissingCondition };
        } else if (detail.conditionOperator === 'NI' && matches.length) {
            return { status: RequisiteStatus.MissingCondition };
        } else {
            return { status: RequisiteStatus.Allowed };
        }

    } else {
        if (detail.conditionData === INSTRUCTOR_PERMIT_PLAN) {
            return validateInstructorPermitPlan(detail, student);
        }

        if (detail.conditionOperator === 'EQ' &&
            studentPlans.indexOf(detail.conditionData) !== -1
        ) {
            return {status: RequisiteStatus.Allowed};
        }

        if (detail.conditionOperator === 'NE' &&
            studentPlans.indexOf(detail.conditionData) === -1
        ) {
            return {status: RequisiteStatus.Allowed};
        }
        return {status: RequisiteStatus.MissingCondition};
    }
};

const validateConditionAcademicSubplan = (detail: RequirementGroupDetailsWithDepth | RequirementLine, student: StudentDetails):RequisiteValidationResult => {
    const studentSubplans: string[] = student.academicSubplans.map(o => o.academicSubplan);

    if (detail.conditionOperator === 'EQ' &&
        studentSubplans.indexOf(detail.conditionData) !== -1
    ) {
        return {status: RequisiteStatus.Allowed};
    }

    if (detail.conditionOperator === 'NE' &&
        studentSubplans.indexOf(detail.conditionData) === -1
    ) {
        return {status: RequisiteStatus.Allowed};
    }
    return {status: RequisiteStatus.MissingCondition};
};

const validateConditionMilestone = (conditionDetail: ConditionDetail, student: StudentDetails): RequisiteValidationResult => {
    const studentMilestones = student.milestones;

    const completedMilestone: Milestone | undefined = _.find<Milestone>(studentMilestones, sm => {
        if (conditionDetail.milestoneLevel) {
            return conditionDetail.milestoneLevel === sm.level && conditionDetail.milestone === sm.milestoneName;
        }
        return conditionDetail.milestone === sm.milestoneName;
    });

    if (completedMilestone) {
        return {status: RequisiteStatus.Allowed};
    } else {
        return {status: RequisiteStatus.MissingCondition};
    }
};

const validateConditionCGA = (detail: RequirementGroupDetailsWithDepth | RequirementLine | ConditionLine, student: StudentDetails): RequisiteValidationResult => {
    const summary: EnrollmentSummary = summarizeStats(student.enrollments);
    const pass = compareIntegerOperator(detail.conditionOperator, summary.cumulativeGpa, parseFloat(detail.conditionData));
    if (pass) {
        return {status: RequisiteStatus.Allowed};
    } else {
        return {status: RequisiteStatus.MissingCondition};
    }
};

const validateConditionAcademicLevel = (detail: RequirementGroupDetailsWithDepth | RequirementLine, student: StudentDetails): RequisiteValidationResult => {
    const summary: EnrollmentSummary = summarizeStats(student.enrollments);
    const pass = compareIntegerOperator(detail.conditionOperator, summary.totalUnits, parseFloat(detail.conditionData));
    if (pass) {
        return {status: RequisiteStatus.Allowed};
    } else {
        return {status: RequisiteStatus.MissingCondition};
    }
};

function _validateConditionDynamic(detail: RequirementGroupDetailsWithDepth | RequirementLine, student: StudentDetails): RequisiteValidationResult {
    const conditionConnect = detail.conditionSpec.connect;
    if (!conditionConnect) {
        console.error('RequisiteStatus.UnknownError conditionConnect is empty');
        return {
            status: RequisiteStatus.UnknownError,
            message: 'conditionConnect is empty',
            meta: conditionConnect
        };
    }

    const conditions: Condition[] = parseDynamicConditions(detail);

    for (let i = 0; i < conditions.length; i++) {
        const c = conditions[i];
        let result: RequisiteValidationResult;

        if ('conditionCode' in c) {
            switch (c.conditionCode) {
                case 'TST':
                    result = validateConditionTest(c, student);
                    break;
                case 'GRP':
                    result = validateConditionStudentGroup(c, student);
                    break;
                case 'GRS':
                    result = validateConditionStudentGroup(c, student);
                    break;
                case 'CGA':
                    result = validateConditionCGA(c, student);
                    break;
                default:
                    console.error('RequisiteStatus.UnknownError unknown conditionCode in dynamic condition');
                    return {
                        status: RequisiteStatus.UnknownError,
                        message: 'Unknown conditionCode in Dynamic Condition',
                        meta: c
                    };
            }

        } else if (c.milestone) {
            result = validateConditionMilestone(c, student);
        } else {
            console.error('invalid condition');
            return {
                status: RequisiteStatus.UnknownError,
                message: 'Invalid Condition. Missing both conditionCode and milestone',
                meta: c
            };
        }

        if (conditionConnect === 'OR' && result.status === RequisiteStatus.Allowed) {
            return {status: RequisiteStatus.Allowed};
        }

        if (conditionConnect === 'AND' && result.status !== RequisiteStatus.Allowed) {
            return {status: RequisiteStatus.MissingCondition};
        }
    }

    // if connect is OR then function would have returned early if any one of the conditions was met. So at this point we fail
    if (conditionConnect === 'OR') {
        return {status: RequisiteStatus.MissingCondition};
    // The inverse is true for AND.
    } else {
        return {status: RequisiteStatus.Allowed};
    }
}

const validateCondition = _validateCondition;

function _validateCondition(detail: RequirementGroupDetailsWithDepth | RequirementLine, student: StudentDetails): RequisiteValidationResult {

    const { conditionCode } = detail;

    if (conditionCode === 'LVL') {
        return validateConditionAcademicLevel(detail, student);

    } else if (conditionCode === 'TST') {
        return validateConditionTest(detail, student);

    } else if (conditionCode === 'GRP' || conditionCode === 'GRS') {
        return validateConditionStudentGroup(detail, student);

    } else if (detail.conditionCode === 'PL' || detail.conditionCode === 'PLS') {
        return validateConditionAcademicPlan(detail, student);

    } else if (detail.conditionCode === 'PR' || detail.conditionCode === 'PRS') {
        return validateConditionAcademicProgram(detail, student);

    } else if (detail.conditionCode === 'TBL') {
        return _validateConditionDynamic(detail, student);

    } else if (detail.conditionCode === 'SPL') {
        return validateConditionAcademicSubplan(detail, student);

    } else if (detail.conditionCode === 'CGA') {
        return validateConditionCGA(detail, student);

    } else {
        console.error('unknown conditionCode');
        return {
            status: RequisiteStatus.UnknownError,
            message: 'Unknown conditionCode',
            meta: detail
        };
    }
}

// TODO: refactor this to include co-reqs. it'll probably be better to just combine both enrollments and shopping cart
// into a single array based on requisiteType, and then parse the coreq errors on component level.
const validateLineCourseList = (detail: RequirementLine, student: StudentDetails): RequisiteValidationResult => {
    const { courses } = detail;

    let completedCourses: CourseExtract[] = getCompletedAndTransferredCourses(student);
    if (detail.groupRequisiteType === 'CO') {
        completedCourses = completedCourses.concat(student.shoppingCart);
    }

    const matches: CourseExtract[] = [];

    for (let i = 0; i < courses.length; i++) {

        const requisiteCourse: RequisiteCourse = convertToRequisiteCourse(courses[i]);
        const isWildCard = courses[i].isWildCard;

        for (let j = 0; j < completedCourses.length; j++) {

            if (isWildCard === 'Y') {
                if (requisiteCourse.subject === completedCourses[j].subject &&
                    compareWildcardPattern(requisiteCourse.courseNumber, completedCourses[j])) {
                    matches.push(completedCourses[j]);
                }

            } else {
                if (requisiteCourse.courseId === completedCourses[j].courseId) {
                    matches.push(completedCourses[j]);
                }
            }
        }
    }

    if (detail.isAntirequisite) {
        console.log('isAntirequisite: ', detail.isAntirequisite);
        return {
            status: matches.length ? RequisiteStatus.Antirequisite : RequisiteStatus.Allowed,
            completed: matches
        };
    }

    // TODO: handle rest of course parameters e.g maxGpa, minX...


    if (detail.minCoursesRequired) {
        if (matches.length < detail.minCoursesRequired) {
            return {
                status: RequisiteStatus.MissingMinimumCourses,
                completed: matches,
            };
        } else {
            return {status: RequisiteStatus.Allowed};
        }
    }

    if (detail.minUnitsRequired) {
        const totalUnitsEarned: number = _.sumBy(matches, 'unitsEarned');
        if (totalUnitsEarned < detail.minUnitsRequired) {
            return {
                status: RequisiteStatus.MissingMinimumUnits,
                completed: matches
            };
        } else {
            return {status: RequisiteStatus.Allowed};
        }
    }

    if (!matches.length) {
        return {status: RequisiteStatus.MissingPreReq};
    }

    return {status: RequisiteStatus.Allowed};
};


const validateRequirementLine = (detail: RequirementLine, student: StudentDetails): RequirementLine => {
    if (detail.lineDetailType === 'CLST') {
        console.log('is class list');
        return {
            ...detail,
            status: validateLineCourseList(detail, student),
        };
    } else {
        return {
            ...detail,
            status: validateCondition(detail, student),
        };
    }
};

const isValid = (detail: NonNullable<RequirementGroupDetails>): boolean => {
    if (!detail.status) {
        console.error('detail missing RequisiteStatus: ', detail);
        throw new Error('detail missing RequisiteStatus');
    }
    return detail.status.status === RequisiteStatus.Allowed || detail.status.status === RequisiteStatus.CoreqAllowed;
};

const toBoolStatement = (detail: NonNullable<RequirementGroupDetails>): string => {
    let boolFlag: string;
    if (detail.status) {
        boolFlag = `${isValid(detail)}`;
        // return `${detail.connect}${detail.parenthesis === '(' ? '(' : ''}${isValid(detail)}${detail.parenthesis === ')' ? ')' : ''}`;

    } else if (detail.requirements) {
        boolFlag = `(${convertToBooleanExpression(detail.requirements)})`;
    } else {
        throw new Error('detail needs to have RequisiteStatus or within nested RequirementLines');
    }

    return `${detail.connect}${detail.parenthesis === '(' ? '(' : ''}${boolFlag}${detail.parenthesis === ')' ? ')' : ''}`;
};

const convertToBooleanExpression = (details: NonNullable<RequirementGroupDetails | RequirementLine>[]): string => {
    const statements: string[] = details.map(toBoolStatement);

    let expression = statements.join('')
        .replace(/AND/g, ' && ')
        .replace(/OR/g, ' || ');


    // normalize parantheses
    const numLeft = (expression.match(/\(/g) || []).length;
    const numRight = (expression.match(/\)/g) || []).length;

    // console.log('numLeft: ', numLeft);
    // console.log('numRight: ', numRight);
    const diff = numLeft - numRight;
    if (diff > 0) {
        expression = _.padEnd(expression, expression.length + diff, ')');
    } else if (diff < 0) {
        expression = _.padStart(expression, expression.length + (diff * -1), '(');
    }

    return expression;
};

export interface RequirementsValidationResult {
    courseId: string;
    expression: string; // string expression to be passed into eval()
    result: boolean;
    errorMsg?: any;
    coreqsRelatedGroupings: NonNullable<RequirementGroupDetails>[];
    whatIfAddedCoreqsExpression: string;
    whatIfAddedCoreqsResult: boolean | null; // can be null if original expression is already true then no point in checking whatIf
    requirementGroupDescriptionFull: string;
}

interface EvalResult {
    result: boolean;
    errorMsg?: any;
}
// const safeEval = (expression: string): EvalResult => {
//     let result = false;
//     try {
//         const numLeft = (expression.match(/\(/g) || []).length;
//         const numRight = (expression.match(/\)/g) || []).length;
//
//         const diff = numLeft - numRight;
//         if (diff > 0) {
//             expression = _.padEnd(expression, expression.length + diff, ')');
//         } else if (diff < 0) {
//             expression = _.padStart(expression, expression.length + (diff * -1), '(');
//         }
//
//         const evalResult: string = eval(expression);
//         result = JSON.parse(evalResult);
//         return { result };
//     } catch (e) {
//         return {
//             result: false,
//             errorMsg: 'Error parsing expression. Confirm all open parentheses have a closing end.';
//         };
//     }
// };

export const validateRequirements = (courseClass: CourseInfo, student: StudentDetails): RequirementsValidationResult => {
    console.log('validateRequirements courseClass: ', courseClass);
    const gd: RequirementGroupDetailsWithDepth[] = courseClass ? parseParenthesesDepth(courseClass) : [];
    const groupDetails: RequirementGroupDetailsWithDepth[] = _.cloneDeep(gd);

    if (!groupDetails.length) {
        return {
            coreqsRelatedGroupings: [],
            courseId: courseClass.courseId,
            expression: 'true',
            result: true,
            whatIfAddedCoreqsExpression: '',
            whatIfAddedCoreqsResult: null,
            requirementGroupDescriptionFull: courseClass.requirementGroupDescriptionFull!,
        };
    }

    groupDetails.forEach((detail) => {
        if (detail.groupLineType === 'CRSE') {
            detail.status = validateGroupLineCourse(detail, student);
        } else if (detail.groupLineType === 'CRSW') {
            detail.status = validateGroupLineWildCard(detail, student);
        } else if (detail.groupLineType === 'COND') {
            detail.status = validateCondition(detail, student);
        } else {
            detail.requirements = detail.requirements.map(r => {
                return validateRequirementLine({
                    ...r,
                    groupRequisiteType: detail.requisiteType
                }, student);
            });
        }
    });

    const result: RequirementsValidationResult = {
        courseId: courseClass.courseId,
        expression: convertToBooleanExpression(groupDetails),
        result: false,
        coreqsRelatedGroupings: [],
        whatIfAddedCoreqsExpression: '',
        whatIfAddedCoreqsResult: null,
        requirementGroupDescriptionFull: courseClass.requirementGroupDescriptionFull!,
    };

    try {
        const numLeft = (result.expression.match(/\(/g) || []).length;
        const numRight = (result.expression.match(/\)/g) || []).length;

        const diff = numLeft - numRight;
        if (diff > 0) {
            result.expression = _.padEnd(result.expression, result.expression.length + diff, ')');
        } else if (diff < 0) {
            result.expression = _.padStart(result.expression, result.expression.length + (diff * -1), '(');
        }

        const evalResult: string = eval(result.expression);
        result.result = JSON.parse(evalResult);
    } catch (e) {
        result.result = false;
        result.errorMsg = 'Error parsing expression. Confirm all open parentheses have a closing end.';
    }

    if (!result.errorMsg) {
        if (!result.result) {
            const whatIfAddedCoreqs = _.cloneDeep(groupDetails);
            whatIfAddedCoreqs.forEach((detail) => {
                if (isCourseRelatedCorequisite(detail)) {
                    if (detail.groupLineType === 'RQ') {
                        detail.requirements.forEach((line) => {
                            if (line.lineDetailType === 'CLST') {
                                line.status!.status = RequisiteStatus.Allowed;
                            }
                        });

                    } else {
                        detail.status!.status = RequisiteStatus.Allowed;
                    }
                }
            });
            result.whatIfAddedCoreqsExpression = convertToBooleanExpression(whatIfAddedCoreqs);
            // no need to try/catch since this is an exact replica of a successfully parsed expression
            result.whatIfAddedCoreqsResult = eval(result.whatIfAddedCoreqsExpression);
            result.coreqsRelatedGroupings = parseCourseCorequisiteGroups(whatIfAddedCoreqs);
        }
    }
    return result;
};
