import { EnrollmentSummary, StudentEnrollment } from '@/api/types';
import {StudentInfoWithDegree} from '@/degrees/audit/types';
import _ from 'lodash';
import {isSummerTerm, termMappings} from '@/constants/terms';
import tw from 'twin.macro';
import React from 'react';

// Fall/Spring semester only
// exclude summer terms
export function getMostRecentSemester(unitsBySemester: {[strm: string]: number}): string | undefined {
    const terms: string[] = Object.keys(unitsBySemester);
    const noSummerTerms = terms.filter(strm => !isSummerTerm(strm));
    // exclude summer terms
    const mostRecentSemester = _.max(noSummerTerms);
    return mostRecentSemester;
}

export const summarizeStats = (enrollments: StudentEnrollment[]): EnrollmentSummary => {
    let totalUnits = 0;
    let totalGradedUnits = 0;
    let weightedGradePoints = 0;

    const unitsBySemester: {[strm: string]: number} = {};

    enrollments.forEach((o) => {

        if (o.earnCredit === 'Y') {
            totalUnits += o.unitsEarned;

            unitsBySemester[o.strm] = (unitsBySemester[o.strm] || 0) + o.unitsEarned;

            if (o.gradePoints) {
                const weightedPoints = (o.gradePoints * o.unitsEarned);
                totalGradedUnits += o.unitsEarned;
                weightedGradePoints += weightedPoints;
            }
        }
    });

    const cumulativeGpa = (weightedGradePoints / totalGradedUnits);

    const mostRecentSemester = getMostRecentSemester(unitsBySemester);
    let mostRecentUnits: number | undefined;
    if (mostRecentSemester) {
        mostRecentUnits = unitsBySemester[mostRecentSemester];
    }

    const summary: EnrollmentSummary = {
        totalUnits,
        cumulativeGpa,
        unitsBySemester,
        mostRecentSemester,
        mostRecentUnits,
    };
    return summary;
};
